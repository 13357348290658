import React from "react";
import "./content-methodik.css"

const Square = ({type, label}) => {
    return (
        <div className={'square'}>
                <div className={'icon ' + type}/>
                <h2 className={'label'}>{label}</h2>
        </div>
    );
}

export const ContentMethodik = () => {
    return (
        <div>
            <h1>Methodik</h1>
            <div className={'square-row'}>
                <Square type={'find'} label={'Find authenticity'}/>
                <Square type={'create'} label={'Create identity'}/>
                <Square type={'spark'} label={'Spark innovation'}/>
            </div>
        </div>
    )
};
