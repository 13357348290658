import React from "react";
import image_bz from "../static/E105_BZ.jpg";
import image_uz from "../static/E105_UZ.jpg";


export const ContentContact = () => {
    return (
        <div>
            <h1>Contact</h1>
            <p>
                Wünschen Sie eine Reise in unsere Archive? Möchten Sie einen klaren Blick auf die Gegenwart? Oder wollen
                Sie gemeinsam Richtung Zukunft?
            </p>
            <p>
                Alles machbar. Anruf genügt. Und das Beste: Fragen kostet nichts. Und Antworten zunächst auch nicht.
            </p>
            <img src={image_bz} width={200}/>
            <p>
                <strong>Dr. Bernhard Zünkeler</strong><br/>
                <i>Geschäftsführer</i><br/>
                mail: <a href={"mailto:bernhard.zuenkeler@e105.de"}>bernhard.zuenkeler@e105.de</a><br/>
                phone: ++49 (0)151 11 567 355
            </p>
            <img src={image_uz} width={200}/>
            <p>
                <strong>Ulrich Zünkeler</strong><br/>
                <i>Geschäftsführer</i><br/>
                mail: <a href={"mailto:ulrich.zuenkeler@e105.de"}>ulrich.zuenkeler@e105.de</a><br/>
                phone: ++49 (0)173 783 780 2
            </p>
        </div>
    )
};
