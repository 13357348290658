import React from "react";
import {Content} from "./components/content";
import {SlideShow} from "./components/slideshow";
import {ContentTools} from "./components/content-tools";
import {ContentProducts} from "./components/content-products";
import {ContentBenefits} from "./components/content-benefits";
import {ContentIntro} from "./components/content-intro";
import {Imprint} from "./components/imprint";
import {ContentMethodik} from "./components/content-methodik";
import {ContentContact} from "./components/content-contact";


const contents = [
    ContentIntro,
    ContentMethodik,
    ContentTools,
    ContentBenefits,
    ContentProducts,
    ContentContact,
];


export const App = () => {

    const renderSection = (ContentComponent, index) => {
        return (
            <section key={`section-${index}`}>
                <Content>
                    <ContentComponent/>
                </Content>
                <SlideShow/>
            </section>
        )
    }

    return (
        <div>
            <SlideShow>
                <div className={'logo'}/>
            </SlideShow>

            {contents.map((renderSection))}

            <Imprint/>
        </div>
    )
};
