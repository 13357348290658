import React from "react";

export const ContentIntro = () => {
    return (
        <div>
            <p>
                <span className={'capitalize'}>E</span> wie Energy, Enabling, Efficiency, Emotion, Evolution,
                Exaltation, Education, Emancipation, Evaluation
                und unzählige mehr. Denn sicher ist: Es gibt mehr Quellen und Wege, als man sie gemeinhin nutzt: 105...?
            </p>
            <p>
                Anders gesagt: E105 ist eine Gesellschaft, die Unternehmen und Institutionen bei ihrer Wirkung nach
                innen aber auch nach außen weiterhilft und sie dem Ziel der gelebten <strong>Innovationskultur näher
                bringt.
                Denn Veränderung ist täglich die einzige Konstante.</strong>
            </p>
            <p>
                Dabei spielt die Kraft von Authentizität, Identität,
                Inspiration und Purpose eine entscheidende Rolle. E105 wendet hierfür einen neu entwickelten
                Maßnahmenkatalog in mehreren Phasen an.
            </p>
            <p>
                Sie sucht, schafft und revitalisiert gemeinsam mit den Kunden
                die eigene betriebliche emotionale Intelligenz! E105 kooperiert in verschiedene Richtungen: von
                Wissenschaft bis zu Künstlern und digital Minds oder anderen Kreativen, die Out-of-the-Box leben und
                über die Jahre ein ganz besonderes Gespür entwickelt haben. Hervorzuheben an dieser Stelle ist
                sicher die enge Zusammenarbeit mit der Künstler Genossenschaft Freeters.
            </p>
            <p>
                Die Gesellschaft denkt und arbeitet in
                Kooperation statt Konkurrenz. Mit Playern einander übergreifend in drei Tool-Boxen (Bisher. Denn auch
                für E105 gilt der Satz über Veränderung): Im Sinne der Effizienz und Kostenersparnis spielt die
                Schaffung von Synergien sowie der Wandel von Überlappung hin zu Verzahnung in Unternehmen und
                Institutionen eine wichtige Rolle.
            </p>
        </div>
    )
};
