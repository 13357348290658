import React from "react";
import './content.css';

export const Content = ({children}) => {
    return (
        <div className={'content-container top-border'}>
            <div className={'content'}>
                {children}
            </div>
        </div>
    )
};
