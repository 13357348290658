import React from "react";
import TypeWriter from "./type-writer";

const BREAK = 1000;

const ART_WORX = [
    'inspiration',
    BREAK,
    'inspiration',
    BREAK,
    'imagination',
    BREAK,
    'intention',
    BREAK,
    'iteration',
    BREAK,
    'intuition',
    BREAK,
    'improvisation',
    BREAK,
    'interaction',
    BREAK,
];


const SPRACH_ACROBATIX = [
    'five senses',
    BREAK,
    'semantics',
    BREAK,
    'x-border-logic',
    BREAK,
    'dialogue-triggering',
    BREAK,
];

const HUMORISTIX = [
    'ready wits',
    BREAK,
    'calculated disaster',
    BREAK,
    'anti-hero-strength',
    BREAK,
    'comedian-harmonies',
    BREAK,
]

const writerBoxStyle = {
    border: '4px solid black',
    padding: '3px 8px',
    fontWeight: '700',
    minWidth: '20px'
};

const headlineStyle = {
    marginBottom: "0px"
};

const pStyle = {
    marginTop: "10px"
};

const Writer = ({texts, className}) => {
    return <span style={{...writerBoxStyle}} className={className}><TypeWriter
        steps={texts}
        loop={Infinity}
        wrapper="span"
    /></span>
};

export const ContentTools = () => {

    return (
        <div>
            <h1>Tools</h1>
            <h2 style={headlineStyle}>Art Worx</h2>
            <p style={pStyle}>discovering pathway of innovation through <Writer texts={ART_WORX}/>
            </p>
            <h2 style={headlineStyle}>Sprach Akrobatix</h2>
            <p style={pStyle}>discovering true power of language with <Writer texts={SPRACH_ACROBATIX}/></p>
            <h2 style={headlineStyle}>Humoristix</h2>
            <p style={pStyle}>discovering the 5th Element of communication in <Writer texts={HUMORISTIX}/></p>
        </div>
    )
};
