import React, {useCallback, useEffect, useRef, useState} from "react";
import './slideshow.css'
import image_0 from '../static/slides/0.jpg';
import image_1 from '../static/slides/00.jpg';
import image_2 from '../static/slides/01.jpg';
import image_3 from '../static/slides/04.jpg';
import image_4 from '../static/slides/05.jpg';
import image_5 from '../static/slides/06.jpg';
import image_6 from '../static/slides/07.jpg';
import image_7 from '../static/slides/08.jpg';
import image_8 from '../static/slides/09.jpg';
import image_9 from '../static/slides/10.jpg';
import image_10 from '../static/slides/11.jpg';
import image_11 from '../static/slides/12.jpg';
import image_12 from '../static/slides/13.jpg';
import image_13 from '../static/slides/14.jpg';
import image_14 from '../static/slides/15.jpg';
import image_15 from '../static/slides/16.jpg';
import image_16 from '../static/slides/17.jpg';
import image_17 from '../static/slides/18.jpg';
import image_18 from '../static/slides/19.jpg';
import classNames from "classnames";

const images = [
    image_0,
    image_1,
    image_2,
    image_3,
    image_4,
    image_5,
    image_6,
    image_7,
    image_8,
    image_9,
    image_10,
    image_11,
    image_12,
    image_13,
    image_14,
    image_15,
    image_16,
    image_17,
    image_18,
];

export const SlideShow = ({children}) => {
    const countRef = useRef(0);

    const nextIndex = useCallback(() => {
        return Math.floor(Math.random() * images.length);
    }, []);

    const [slide, setSlide] = useState(nextIndex());

    const updateSlide = useCallback(() => {
        setSlide(nextIndex());
        countRef.current = setTimeout(updateSlide, 3000 + 2000 * Math.random());
    }, [setSlide, nextIndex]);

    useEffect(() => {
        updateSlide();
        return () => clearTimeout(countRef.current);
    }, []);

    return (
        <div className={`slide-show`}>
            {images.map((image, index) => {
                return (
                    <div
                        key={'slide-' + index}
                        className={classNames('slide', {show: index === slide})}
                        style={{backgroundImage: `url("${image}")`}}
                    />
                )
            })}
            <div className="slide-content">{children}</div>
        </div>
    )
};
