import React from "react";

export const ContentProducts = () => {
    return (
        <div>
            <h1>Products</h1>
            <h2>Art Worx</h2>
            <p><i>job shadowing, Treasure hunt, Serious play, Creathon, Panoramica, 360° Portrait, EVP-Check, Digital Gaming, Guerilla Action, Rapid Prototyping, Space-R-Interfaces</i></p>
            <h2>Sprach Akrobatix</h2>
            <p><i>Language Scan, Mark the Bench, Finding the plot, USP-Discovery, Writing scripts, Start Dialogue, Pure headlining, New naming, Five sensing, Channel strategy, Clean copywriting</i></p>
            <h2>Humoristix</h2>
            <p><i>Humor index, Fun score, Mystery shopping, Fitting timing, Verschlemmerung, Objectification, Joke box, Disaster rodeo</i></p>
        </div>
    )
};
