import React from "react";
import "./content-benefits.css"

const CHAR_MAP = new Map();
CHAR_MAP.set('i' , '#fcbf49');
CHAR_MAP.set('a' , 'blue');
CHAR_MAP.set('e' , 'green');
CHAR_MAP.set('y' , 'red');

const colorChars = (input) => {
    return input.split('').map((char, index) => {
        if(CHAR_MAP.has(char)){
            return <span key={`${char}_${index}`} style={{color:CHAR_MAP.get(char)}}>{char}</span>
        } else {
            return char;
        }
    })
}

export const ContentBenefits = () => {
    return (
        <div>
            <h1>Benefits</h1>
            <h2>Art Sparks {colorChars('Creativity')}</h2>
            <h2>Language builds______Bridges</h2>
            <h2>Humor <span style={{opacity: '0.7'}}>dissolves</span> <span style={{opacity: '0.4'}}>conflicts</span></h2>
        </div>
    )
};
